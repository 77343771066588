@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css 또는 global.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* 나눔스퀘어 네오 폰트 불러오기 */
@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeo-aLt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeo-bRg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeo-cBd.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeo-dEb.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquareNeo';
  src: url('./fonts/NanumSquareNeo-eHv.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* TailwindCSS 기본 폰트 설정 */
body {
  margin: 0;
  padding: 0; 
  font-family: 'NanumSquareNeo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 반짝이는 애니메이션 효과 정의 */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(208, 0, 112, 0.7);
  }
  70% {
    box-shadow: 0 0 10px 10px rgba(208, 0, 112, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(208, 0, 112, 0);
  }
}

/* 체험하기 버튼의 반짝임 효과 */
.animate-pulse {
  animation: pulse 1.5s infinite;
}

/* 스크롤바 숨기기 */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* 추가된 애니메이션 */
@keyframes subtle-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(208, 0, 112, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(208, 0, 112, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(208, 0, 112, 0);
  }
}

.animate-subtle-pulse {
  animation: subtle-pulse 2s infinite;
}

@keyframes float {
  0% { transform: translate(0, 0); }
  100% { transform: translate(0, -100vh); }
}

.animation-delay-500 {
  animation-delay: 500ms;
}

.animation-delay-1000 {
  animation-delay: 1000ms;
}

@keyframes float-up {
  0% { transform: translateY(100vh); }
  100% { transform: translateY(-100vh); }
}

.float-square {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #D00070;
  opacity: 0.7;
  animation: float-up 3s linear infinite;
}

.float-square:nth-child(2n) {
  animation-duration: 3.5s;
}

.float-square:nth-child(3n) {
  animation-duration: 4s;
}

@keyframes neon {
  from {
    text-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff;
  }
  to {
    text-shadow: 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #0ff;
  }
}
